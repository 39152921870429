export const getChartURL = ({
	exchangeName,
	symbol
}: {
	exchangeName?: null | string;
	symbol: string;
}) => {
	if (exchangeName) {
		return `/charts/${encodeURIComponent(exchangeName + ':' + symbol)}`;
	} else {
		return `/charts/${encodeURIComponent(symbol)}`;
	}
};
