import type { TDTypes } from '@tickrr/twelvedata';
import type { AreaData, CandlestickData, HistogramData, UTCTimestamp } from 'lightweight-charts';

export type AreaSeries = ReturnType<typeof toAreaSeries>;
export type CandlestickSeries = ReturnType<typeof toCandlestickSeries>;
export type HistogramSeries = ReturnType<typeof toHistogramSeries>;

/**
 * Converts a TDTimeSeries to a line chart series.
 *
 * @param series - The TDTimeSeries to convert.
 * @returns An array of objects representing the line chart series.
 */
export const toAreaSeries = (series: TDTypes.TDValidatedTimeSeries) => {
	return series.map(({ close, datetime, volume }) => {
		return {
			customValues: {
				volume
			},
			time: datetime as UTCTimestamp, // casting is required (see https://tradingview.github.io/lightweight-charts/docs/api#utctimestamp)
			value: close
		} satisfies Pick<AreaData<UTCTimestamp>, 'customValues' | 'time' | 'value'>;
	});
};

/**
 * Converts a TDTimeSeries to a CandlestickData array.
 *
 * @param series - The TDTimeSeries to convert.
 * @returns An array of CandlestickData objects.
 */
export const toCandlestickSeries = (series: TDTypes.TDValidatedTimeSeries) => {
	return series.map(({ close, datetime, high, low, open, volume }) => {
		return {
			close,
			customValues: {
				volume
			},
			high,
			low,
			open,
			time: datetime as UTCTimestamp // casting is required (see https://tradingview.github.io/lightweight-charts/docs/api#utctimestamp)
		} satisfies CandlestickData<UTCTimestamp>;
	});
};

/**
 * Converts a time series to an array of histogram points.
 *
 * @param series - The time series to convert.
 * @param withColor - Optional flag to include color in the histogram points.
 * @returns An array of histogram points with properties 'color', 'time', and 'value'.
 */
export const toHistogramSeries = (
	series: TDTypes.TDValidatedTimeSeries,
	withColor: boolean = false
) => {
	return series.map((point) => {
		const isPriceEndHigher = point.close > point.open;

		return {
			color: withColor ? (isPriceEndHigher ? '#4beca4' : '#ff3d64') : 'rgba(151,161,186,0.5)', // pepper-2
			time: point.datetime as UTCTimestamp, // casting is required (see https://tradingview.github.io/lightweight-charts/docs/api#utctimestamp)
			value: point.volume
		} satisfies Pick<HistogramData<UTCTimestamp>, 'color' | 'time' | 'value'>;
	});
};
